body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* form fixes */

.react-datepicker__input-container input {
    width: 100%;
    height: 38px;
    border: 1px solid #ced4da;
    padding-left: 10px;
}

/* Base */
body {
    background-color: #ececec !important;
}

body a {
    color: #4AA4FF;
}

/* button base bg {#4AA4FF} */

.header {
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.header a {
    color: inherit;
    text-decoration: none;
}

.header .logout-link span {
    margin-left: 10px;
}


/* form-check-as-button */

.form-check-as-button {
}

.form-check-as-button label {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    padding: 0 10px;
    opacity: 1;
    transition: background .4s;
    opacity: .65;
}

.form-check-as-button radio {
}

.form-check-as-button input[type=radio] {
    position: absolute;
    opacity: 0;
}

.form-check-as-button input[type=radio]:checked + label {
    background: #006BD6;
    opacity: 1;
}

.ovarlay-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .15);
    z-index: 12;
}


.highlighted-cell {
    color: #f00;
    background: #ececec !important;
}

button {
    font-size: 14px !important;
}

.excise-table > thead > tr > th {
    background: aliceblue;
    vertical-align: middle;
}

.excise-table thead, .excise-table tbody {
    border-top: none !important;
}

.excise-table th {
    text-align: left;
    color: #000000;
}

.excise-table th ~ th {
    text-align: center;
    color: #6c757d;
}

.excise-table td {
    text-align: right;
}

.excise-table tfoot {
    font-weight: bold;
}

.excise-table > caption {
    font-size: 24px;
    font-weight: bold;
    color: black;
}

.excise-info-panel {
    padding: 1.0rem;
    border-bottom: solid;
    border-width: 2px;
    border-bottom-color: lightgrey;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
